<template>
  <q-dialog
    v-model="isShow"
    persistent
    :maximized="true"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <div class="error-screen text-dark">
      <div class="q-my-auto">
        <p>{{ $t(message) }}</p>

        <div>
          <q-btn
              color="green"
              text-color="white"
              size="sm"
              class="q-ma-md"
              :label="$t('Skip this error')"
              no-caps
              unelevated
              @click="skip"
              v-if="skipEnabled"
          />

          <q-btn
            color="dark"
            text-color="white"
            size="sm"
            :label="$t('Close')"
            no-caps
            unelevated
            @click="close"
          />
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script>
export default {
  name: 'ErrorScreen',
  emits: ['reset', 'skip'],
  props: {
    message: {
      type: String,
      default () {
        return ''
      }
    },
    allowSkip: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isShow: false,
      timeout: null,
      skipEnabled: false,
    }
  },
  watch: {
    message (value) {
      if (value) {
        this.isShow = true
      }
    },
    allowSkip (value) {
      this.skipEnabled = value
    }
  },
  methods: {
    skip () {
      this.isShow = false
      this.$emit('skip')
    },
    close () {
      this.isShow = false
      this.$emit('reset')
    }
  }
}
</script>

<style>
.error-screen {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  background: rgba(255, 87, 51, 0.9);
  word-wrap: break-word;
}

.error-screen p {
  font-size: 1.5rem;
  word-wrap: break-word;
}
</style>
